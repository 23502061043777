<template>
  <section id="landingFeed">
    <!-- {{ id }} {{ pageNumber }} {{ pageSize }} -->

    <AdComponent v-if="themeConfig.landAdPos.includes(0)" adType="detail_ad" :adPosition="0" />

    <section v-if="hasNews" id="el" class="content">
      <h1 class="news-title">
        {{ newsObj?.title }}
      </h1>

      <div class="news-info-date">
        <img src="@/assets/news/dates.png" alt="">
        {{ $global.formatDate($global.dateStringToTimestamp(newsObj?.publishedTime), 'MM-dd-yyyy') }}
      </div>

      <img v-lazy="newsObj?.poster" class="figure_poster" :alt="newsObj?.poster">

      <!-- 中间广告 -->
      <AdComponent v-if="themeConfig.landAdPos.includes(1)" adType="detail_ad" :adPosition="1" />

      <template v-if="newsObj?.content && newsObj?.content.length > 0 ">
        <section v-for="(context, i) in newsObj?.content" :key="i" class="content-row">
          <h2 v-if="context.subTitle" v-html="context.subTitle" />
          <img v-show="i > 0" v-if="context.img" v-lazy="context.img" class="figure"
            :alt="context.subTitle">
          <div v-html="context.content" />
        </section>
      </template>
    </section>

    <!-- 底部广告 -->
    <AdComponent v-if="themeConfig.landAdPos.includes(2)" adType="detail_ad" :adPosition="2" />
  </section>
</template>

<script>
import AdComponent from '@/pages/components/AdComponent.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    AdComponent,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    pageNumber: {
      type: [String, Number],
      required: true
    },
    pageSize: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      content: '',
      listData: [],
      displayList: [],
      count: 6,

      newsObj: {},
      requirDataAlready: false,
      hasNews: false,
      list: [],
      hasNext: false,
      loading: false, // 控制上拉加载的加载状态
      finished: false, // 是否已加载完全部数据
      bomTitle: '-- END --', // 上拉加载完成时的文本
      page: 1,
      isinitialization: false,
      isData: false,
    };
  },
  computed: {
    ...mapGetters({
      host: 'host',
      themeConfig: 'themeConfig',
      adsense: 'adsenseConfig',
      showDebug: 'showDebug',
      domainConfig: 'domainConfig',
      deviceType: 'deviceType',
    }),
  },
  async mounted () {
    try {
      await this.getNews(this.id);
      await this.getList();
      this.loadAdSenseScript();
    } catch (error) {
      console.error('An error occurred in getList:', error);
    }
  },
  activated () {
    console.log('activated landfeed_1 -------------------');
  },

  methods: {
    invalidClass (type) {
      if (type === 'lead' && !this.themeConfig.leadType) {
        return 'params-invalid'
      }
      if (type === 'collection' && !this.themeConfig.collectionType) {
        return 'params-invalid'
      }
      return ''
    },
    goLanding (id) {
      console.log(`landingFeed_1_jumpid_${id}`);
      this.$emit('feedClicked', id);
      // this.$eventrackFb('news_goto_detail', 'click', {
      //   id
      // });
      // this.$router.replace({ name: 'landing', query: { id, thme: this.themeConfig.landType } });
    },
    handleFeedClicked (data) {
      this.$emit('childClicked', data);
    },
    loadAdSenseScript () {
      console.log('landing loadAdSenseScript func');
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        setTimeout(this.displayAd, 600); // 延迟100毫秒初始化广告
        return; // 如果已加载，直接返回
      }
      this.loadScriptConditionally();
    },
    loadAdWithDelay () {
      setTimeout(this.displayAd, 1500); // 延迟100毫秒初始化广告
    },
    loadScriptConditionally () {
      console.log(this.adsense);
      // 判断广告脚本URL是否存在
      if (!this.adsense?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsense.scriptUrl}"]`);
      if (existingScript) {
        console.log('脚本已存在，无需重新添加');
        return;
      }

      const script = document.createElement('script');
      script.src = this.adsense?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
    },
    displayAd () {
      console.log('load ads by push...');
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    },
    fetchContent () {
      // 根据传入的 id 获取内容
      this.$axios.get(`/content?id=${this.id}`).then(response => {
        this.content = response.data;
      });
    },
    async getList () {
      console.log(`第${this.pageNumber}页`);
      if (this.pageNumber >= 5) return //限制5篇
      let url = window.location.href// 获取当前 URL
      let formattedUrl = url.replace(/^https?:\/\//, '')// 移除 http:// 或 https:// 前缀
      formattedUrl = formattedUrl.split('/')[0]// 去掉路径和查询参数，只保留域名部分
      // console.log(`url:${url} 格式化后的url:${formattedUrl}`)
      let domain = this.host ? this.host : formattedUrl// 根据条件设置域名
      console.log(`请求domain:${domain}`)
      domain = domain == 'localhost:8080' ? 'kynews.lol' : domain

      try {
        const res = await this.$global.server.getList({
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          domain: domain, //yarn serve使用时兼容
        })
        console.log(res)
        let data = res.data.articleSummaryList
        this.listData = data;
        this.displayList = this.listData.slice(0, this.pageSize - 1);
        console.log(this.displayList);
        console.log(this.listData[this.pageSize - 1]?.id);
        this.list.push(...this.displayList)
        this.$emit('last-item-id', this.listData[this.pageSize - 1]?.id); // 传递第六个数据的id给父组件

      } catch (err) {
        console.log(err)
      }
    },
    async getNews (id) {
      this.hasNews = false;
      try {
        const res = await this.$global.server.getNews({
          id,
        });

        this.requirDataAlready = true;

        if (res.code != 200) {
          console.log('获取失败');
          return;
        }

        this.hasNews = true;
        this.newsObj = {
          title: res.data.title,
          channel: res.data.channel,
          poster: res.data.articleDetails?.[0]?.img,
          publishedTime: res.data.published,
          content: res.data.articleDetails
        };

      } catch (err) {
        this.hasNews = false;
        this.requirDataAlready = true;
        console.error('An error occurred while getting news:', err);
      }
    },
    fetchListData () {
      this.$axios.get(`/list?start=${this.start}&count=${this.count}`).then(response => {
        this.listData = response.data;
        this.displayList = this.listData.slice(0, 5);
        this.$emit('last-item-id', this.listData[5]?.id); // 传递第六个数据的id给父组件
      });
    }
  }
};
</script>